<template>
    <div>
      <section class="section first full is-vcentered center">
        <div class="feature__cloud"></div>
        <AnimationBg/>
        <div class="container is-vcentered wrap">
          <div class="columns is-multiline is-mobile is-8 is-vcentered">
            <div class="column center cid is-full-mobile" style="word-break: break-word;white-space: normal;">
              <!-- Logo -->
              <img alt="Entfalter Logo" src="../../../assets.entfalter_shared/img/logo.svg" class="logo"><br/>
              <!-- Punchline -->
              <h1 class="punchline ">Das Werkzeug für kluge Berufs- und Lebensentscheidungen</h1>
              <!-- Intro Anim CID -->
            </div>

            <div class="column is-vcentered">
              <h1>Über die App und über uns</h1>
              <h3>

                <router-link to="/about">Team</router-link><br/>
                <router-link to="/konzept">Das Konzept</router-link><br/>
                <router-link to="/impressum">Impressum</router-link><br/>

              </h3>
            </div>
          </div>
        </div>
      </section>


      <section class="section center is-vcentered">

            <div class="card">
                Diese App ist eine Demo-Version des Entfalters. Hier bekommst Du einen Einblick in die Funktionen. Die Vollversion findest Du hier:
              <a href="https://app.entfalter.de"><entfalter-button>Vollversion</entfalter-button></a>
            </div>

        </section>
    </div>

</template>

<script>
	import EntfalterButton from "@/components/entfalter-button.component";
  import AnimationBg     from "@/components/Animation-bg.component";
  export default {
		name: 'Info',
    components: {AnimationBg, EntfalterButton},
  }
</script>
